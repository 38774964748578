@defer (when loadDeferredView()) {
<div class="section">
    <div class="titleContainer">
        <div class="heading">
            <div class="textBlock">{{title()}}</div>
        </div>
        <div class="subText">
            <p class="labelWrapper">
                <span class="label">{{subTitle()}}</span>
                <span class="label2">{{subjectMatter()}}</span>
                <span class="label3">{{subTitleContinued()}}</span>
            </p>
        </div>
        <!-- <div class="timer">
            <div class="vector"><img src="assets/vector-clock-icon.svg" alt="" class="icon" /></div>
            <div class="title">{{getTextTemplateElementText(textTemplateFieldEnum.Text, "timer")}} mins to complete
            </div>
        </div> -->
    </div>
    <div class="contentContainer">
        <div class="tile tile1">
            <div class="tileContainer">
                <div class="iconMask">
                    <div class="iconContainer iconContainerWidth">
                        <img src="assets/phone-tile-icon.svg" alt="" class="tileIcon" />
                    </div>
                </div>
                <div class="tilelabel1">{{getTextTemplateElementText(textTemplateFieldEnum.Text, "affordable")}}</div>
                <div class="description">{{getTextTemplateElementText(textTemplateFieldEnum.SubText, "affordable")}}
                </div>
            </div>
        </div>
        <div class="tile tile2">
            <div class="tileContainer">
                <div class="iconMask">
                    <div class="iconContainer">
                        <img src="assets/apple-tile-icon.svg" alt="" class="tileIcon2" />
                    </div>
                </div>
                <div class="tilelabel1">{{getTextTemplateElementText(textTemplateFieldEnum.Text, "peace-of-mind")}}
                </div>
                <div class="description">{{getTextTemplateElementText(textTemplateFieldEnum.SubText, "peace-of-mind")}}
                </div>
            </div>
        </div>
        <div class="tile tile3">
            <div class="tileContainer">
                <div class="iconMask">
                    <div class="iconContainer iconContainerWidth">
                        <img src="assets/healthier-icon.png" alt="" class="tileIcon3" />
                    </div>
                </div>
                <div class="tilelabel2">{{getTextTemplateElementText(textTemplateFieldEnum.Text, "healthier")}}</div>
                <div class="description">{{getTextTemplateElementText(textTemplateFieldEnum.SubText, "healthier")}}
                </div>
            </div>
        </div>
    </div>
    <div class="actions">
        <button mat-button class="mainButton" (click)="actionClicked()">{{buttonTitle()}}</button>
    </div>
</div>
}@placeholder {
<mat-spinner></mat-spinner>
}