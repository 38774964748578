import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'mw-button',
  standalone: true,
  imports: [],
  templateUrl: './mw-button.component.html',
  styleUrl: './mw-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MwButtonComponent {
  @Input() title = '';

  clicked!: string;

  onClick(): void {
    this.clicked = "clicked";
  }
}
