<block-ui>
  <mat-dialog-content class="paddingZero">
    <div class="modalHeader">
      <div class="group"><img src="assets/select-session-icon.svg" alt="" class="icon" /></div>
      <div class="titleContainer">
        <div class="mainTitle">Select your session type</div>
      </div>
      <button mat-icon-button class="closeButton" (click)="onCloseClicked()" tabindex="-1">
        <mat-icon aria-hidden="false" fontIcon="close"></mat-icon>
      </button>
    </div>
    <div class="contentContainer">
      <div class="accordion">
        <div class="accordionTitle">
          <div class="accordionContent">
            <div class="title">{{textTemplateService.getSectionElementText("session-type", "new-meeting")}}</div>
            <div class="subText">{{textTemplateService.getSectionElementSubText("session-type", "new-meeting")}}</div>
          </div>
          <button mat-button class="startButton" (click)="onSessionTypeClicked(selectedSessionTypes.NewMeeting)">Start</button>
        </div>
      </div>
      @if(this.hasExistingJourney) {
        <div class="accordion">
          <div class="accordionTitle">
            <div class="accordionContent">
              <div class="title">{{textTemplateService.getSectionElementText("session-type", "continue-meeting")}}</div>
              <div class="subText">{{textTemplateService.getSectionElementSubText("session-type", "continue-meeting")}}</div>
            </div>
            <button mat-button class="commonButton" (click)="onSessionTypeClicked(selectedSessionTypes.ContinueMeeting)">Continue</button>
          </div>
        </div>
        <div class="accordion">
          <div class="accordionTitle">
            <div class="accordionContent">
              <div class="title">{{textTemplateService.getSectionElementText("session-type", "adviser-notes")}}</div>
              <div class="subText">{{textTemplateService.getSectionElementSubText("session-type", "adviser-notes")}}</div>
            </div>
            <button mat-button class="commonButton" (click)="onSessionTypeClicked(selectedSessionTypes.AdviserNotes)">Edit</button>
          </div>
        </div>
      }
    </div>
  </mat-dialog-content>
</block-ui>