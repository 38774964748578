import { BlockUI, BlockUIModule, NgBlockUI } from 'ng-block-ui';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { ActionsService } from '../../../services/actions.service';
import { AdviserService } from '../../../services/adviser.service';
import { Client } from '../../../entities/household';
import { CommonModule } from '@angular/common';
import { ConfirmationDialogComponent } from '../../../shared/confirmation-dialog/confirmation-dialog.component';
import { HouseholdService } from '../../../services/household.service';
import { JourneyClient } from '../../../entities/journey-client';
import { JourneyClientService } from '../../../services/journey-client.service';
import { JourneyLogType } from '../../../enums/journey-log-type';
import { JourneyLogsService } from '../../../services/journey-logs.service';
import { JourneyService } from '../../../services/journey.service';
import { JourneyStatuses } from '../../../enums/journey-status';
import { MasterDataService } from '../../../services/master-data.service';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MwButtonComponent } from '../../../controls/mw-button/mw-button.component';
import { Router } from '@angular/router';
import { SessionTypes } from '../../../enums/session-type';
import { TextTemplateService } from '../../../services/text-template.service';
import { createGuid } from '../../../shared/util';
import { firstValueFrom } from 'rxjs';
import moment from 'moment';

@Component({
  selector: 'select-session-type',
  standalone: true,
  imports: [MatButtonModule, MatDialogActions, MatDialogClose, MatDialogTitle, MatDialogContent, MwButtonComponent, MatIconModule, MatProgressSpinner, BlockUIModule, CommonModule],
  templateUrl: './select-session-type.component.html',
  styleUrl: './select-session-type.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectSessionTypeComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<SelectSessionTypeComponent>,
    private journeyService: JourneyService,
    private householdService: HouseholdService,
    private journeyClientService: JourneyClientService,
    private actionsService: ActionsService,
    private router: Router,
    public textTemplateService: TextTemplateService,
    private journeyLogsService: JourneyLogsService,
    public dialog: MatDialog,
    private adviserService: AdviserService
  ) { }

  @BlockUI() blockUI!: NgBlockUI;
  selectedSessionTypes = SessionTypes;
  hasExistingJourney = false;

  ngOnInit() {
    if (this.journeyClientService.primaryJourneyClient()) {
      this.hasExistingJourney = true;
    }
  }

  onCloseClicked(): void {
    this.dialogRef.close();
  }

  async onSessionTypeClicked(selectedSessionType: SessionTypes): Promise<void> {
    this.blockUI.start();
    this.journeyService.selectedSessionType = selectedSessionType;
    switch (selectedSessionType) {
      case SessionTypes.NewMeeting:
        await this.createJourney(SessionTypes.NewMeeting);
        await this.createJourneyClients();
        break;
      case SessionTypes.ContinueMeeting:
        await this.createJourney(SessionTypes.ContinueMeeting);
        await this.createJourneyClientsIfMissing();
        break;
      case SessionTypes.AdviserNotes:
        await this.createJourney(SessionTypes.AdviserNotes);
        await this.createJourneyClientsIfMissing();
        break;
      default:
        break;
    }

    await this.refreshData(selectedSessionType);
    await this.journeyService.updateJourneyDataSuccessfullyRefreshed(true);

    const createDate = moment();
    await firstValueFrom(this.journeyLogsService.saveJourneyLog([
      this.journeyLogsService.createJourneyLog(
        JourneyLogType.Session,
        this.journeyService.getNonNullableJourney().journeyID,
        `${MasterDataService.getSessionTypes().find(x => x.iD === Number(selectedSessionType))?.name}, ${this.adviserService.getLoggedInUserName()}, ${createDate.format('dddd, MMMM Do YYYY, h:mm:ss a Z')}, ${this.householdService.getAttendees()}`,
        void null,
        void null,
        createDate.toDate(),
        this.journeyService.getNonNullableJourney().journeyInstanceID,
        this.journeyClientService.getNonNullablePrimaryClientID()
      )]));

    this.blockUI.stop();

    await this.router.navigate(["/privacy"]);
    this.dialogRef.close();
  }

  private async createJourney(sessionType: SessionTypes): Promise<void> {
    let journeyID: string | null | undefined = createGuid();

    if (this.journeyClientService.primaryJourneyClient() && sessionType !== SessionTypes.NewMeeting) {
      journeyID = this.journeyClientService.primaryJourneyClient()?.journeyID?.toString();
    }

    if (journeyID) {
      this.journeyService.previousJourney = await firstValueFrom(this.journeyService.GetLatestValidJourney(journeyID));

      if (!this.journeyService.previousJourney && sessionType !== SessionTypes.NewMeeting) {
        this.blockUI.stop();
        throw new Error("You have chosen to continue a previous meeting but no valid previous journey record could be found. You will have to start a new meeting.")
      }

      const journey = this.journeyService.createJourney(
        journeyID,
        this.journeyService.getPreviousJourneyStatus() ?? JourneyStatuses.Started.toString(),
        sessionType.toString(),
        createGuid(),
        false,
        false,
        null,
        false
      );

      await firstValueFrom(this.journeyService.saveJourney(journey));

      this.journeyService.journey = journey;
    }
  }

  private async createJourneyClients(): Promise<void> {
    if (!this.householdService.household) return;

    this.journeyClientService.primaryJourneyClient.set(await this.createJourneyClient(this.householdService.household.client));

    if (this.householdService.household.spouse) {
      this.journeyClientService.spouseJourneyClient.set(await this.createJourneyClient(this.householdService.household.spouse));
    }
  }

  private async createJourneyClient(client: Client): Promise<JourneyClient> {
    const journeyClient: JourneyClient = this.journeyClientService.createJourneyClient(client, this.journeyService.journey?.journeyID);

    await firstValueFrom(this.journeyClientService.saveJourneyClient(journeyClient));

    return journeyClient;
  }

  private async createJourneyClientsIfMissing(): Promise<void> {
    if (!this.householdService.household) return;

    if (!this.journeyClientService.primaryJourneyClient()) {
      this.journeyClientService.primaryJourneyClient.set(await this.createJourneyClient(this.householdService.household.client));
    }

    if (!this.journeyClientService.spouseJourneyClient() && this.householdService.household.spouse) {
      this.journeyClientService.spouseJourneyClient.set(await this.createJourneyClient(this.householdService.household.spouse));
    }
  }

  private async refreshData(sessionType: SessionTypes): Promise<void> {
    if (sessionType === SessionTypes.NewMeeting || !this.journeyService.previousJourney) {
      await this.actionsService.refreshDataFromAOS(this.journeyService.getNonNullableJourney().journeyID);

      return;
    }

    if (this.journeyService.previousJourney.successfullySavedToAOS) {
      // The previous journey instance successfully saved to aos.
      // Therefore, we must get the data from aos.
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          title: "Information",
          message: "Welcome back. The client data will be retrieved from AOS because the previous journey session successfully saved to AOS.",
          mainButtonText: "Ok",
          showAltButton: false,
          showIcon: false
        },
      });

      await firstValueFrom(dialogRef.afterClosed());

      await this.actionsService.refreshDataFromAOS(this.journeyService.getNonNullableJourney().journeyID);
    } else {
      // The previous journey instance did not successfully save to aos, either because
      // 1. The user never tried to save to aos
      // 2. The user tried to save but the save failed.
      // In either case, we load the data from the journey.
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          title: "Unable to refresh journey data from AOS",
          message: "Previous journey session was not saved to AOS. Save and exit and restart the journey to refresh the journey data from AOS.",
          mainButtonText: "Ok",
          showAltButton: false,
          showIcon: true
        },
      });

      await firstValueFrom(dialogRef.afterClosed());

      await this.actionsService.loadJourneyData(this.journeyService.previousJourney.journeyInstanceID);

      return;
    }
  }
}
