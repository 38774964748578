import { ChangeDetectionStrategy, Component, EventEmitter, Injector, OnInit, Output, signal } from '@angular/core';
import { catchError, forkJoin, tap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AdviserService } from '../../services/adviser.service';
import { ComponentBase } from '../../component-base';
import { ComponentInitialisationError } from '../../custom-errors/component-initialisation-error';
import { HouseholdService } from '../../services/household.service';
import { JourneyClient } from '../../entities/journey-client';
import { JourneyClientService } from '../../services/journey-client.service';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MwButtonComponent } from '../../controls/mw-button/mw-button.component';
import { Pages } from '../../enums/page';
import { SelectSessionTypeComponent } from './select-session-type/select-session-type.component';
import { TextTemplateField } from '../../enums/text-template-field';

@Component({
  selector: 'welcome',
  standalone: true,
  imports: [SelectSessionTypeComponent, MwButtonComponent, MatIconModule, MatProgressSpinner],
  templateUrl: './welcome.component.html',
  styleUrl: './welcome.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WelcomeComponent extends ComponentBase implements OnInit {
  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private householdService: HouseholdService,
    private journeyClientService: JourneyClientService,
    private injector: Injector,
    private adviserService: AdviserService
  ) {
    super(Pages.Welcome, injector, 'welcome');
  }

  @Output() readonly showSaveAndExitButton = new EventEmitter<boolean>();
  loadDeferredView = signal(false);
  pagesEnum = Pages;
  buttonTitle = signal("Get started");
  title = signal("");
  subTitle = signal("");
  subjectMatter = signal("");
  subTitleContinued = signal("");

  ngOnInit() {
    if (!this.householdService.household) this.showSaveAndExitButton.emit(false);
    if (this.householdService.household) this.buttonTitle.set("Continue");

    const householdID = this.route.snapshot.queryParamMap.get('clientID');

    if (householdID) {
      forkJoin([this.householdService.getHousehold(householdID), this.adviserService.getLoggedInUser()])
      .pipe(
        catchError(err => { throw new ComponentInitialisationError(err) }),
        tap(([household, adviserInfo]) => { 
          this.adviserService.loggedInUser = adviserInfo;

          this.householdService.household = household;
          this.loadText();

          this.journeyClientService.getJourneyClient(this.householdService.household.client.clientID).subscribe({
            next: (journeyClient: JourneyClient | null) => {
              this.journeyClientService.primaryJourneyClient.set(journeyClient);
            },
          });
         })
      )
      .subscribe({
        complete: () => {
          this.loadDeferredView.set(true);
        }
      });
    } else if (this.householdService.household) {
      this.loadText();

      this.loadDeferredView.set(true);
    }
  }

  async actionClicked(): Promise<void> {
    if (this.journeyService.journey) {
      await this.navigateForward();

      return;
    }
    this.dialog.open(SelectSessionTypeComponent);
  }

  private loadText(): void {
    if (!this.householdService.household) return;
    let name = `${this.householdService.household.client.firstName}${(this.householdService.household.spouse ? ` and ${this.householdService.household.spouse.firstName}` : "")}`;

    if (this.journeyClientService.primaryJourneyClient()) {
      name = `${this.journeyClientService.primaryJourneyClient()?.firstName}${(this.journeyClientService.spouseJourneyClient() ? ` and ${this.journeyClientService.spouseJourneyClient()?.firstName}` : "")}`;
    }

    this.title.set(this.getTextTemplateSectionText(TextTemplateField.Title, [{
      code: "<[name]>",
      value: name
    }]));

    const subTitle = this.getTextTemplateSectionText(TextTemplateField.Info);
    const splitSubTile = subTitle.split("<[objectives]>");
    if (splitSubTile[0]) this.subTitle.set(splitSubTile[0]);
    if (splitSubTile[1]) this.subTitleContinued.set(splitSubTile[1]);
    this.subjectMatter.set(this.householdService.household.subjectMatter.map(x => x.categoryName).join(", "));
  }
}
